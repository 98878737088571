(function($) {
    'use strict';

    //Show more text
    $( ".c-button--show-more" ).click(function() {
        if (!$(this).hasClass('is-active')){
            $(this).addClass('is-active');
            $('.c-text--more-content').addClass('is-active');
            $(this).html("Show less");
        } 
        
        else if ($(this).hasClass('is-active'))
        
        {
            $(this).removeClass('is-active');
            $('.c-text--more-content').removeClass('is-active');
            $(this).html("Read more");
        }
    });


    //Show more images
    $( ".c-button--show-more-images" ).click(function() {
        if (!$(this).hasClass('is-active')){
            $(this).addClass('is-active');
            $('.c-row--hide-image').addClass('is-active');
            $(this).html("Show less");
            $('body').addClass('showImages');
        } 
        
        else if ($(this).hasClass('is-active'))
        
        {
            $(this).removeClass('is-active');
            $('.c-row--hide-image').removeClass('is-active');
            $(this).html("Show more");
            $('body').removeClass('showImages');
        }
        
    });

    //Show more images
    $( ".c-button--show-more-images-2" ).click(function() {
        if (!$(this).hasClass('is-active')){
            $(this).addClass('is-active');
            $('.c-row--hide-image-2').addClass('is-active');
            $(this).html("Show less");
            $('body').addClass('showImages');
        } 
        
        else if ($(this).hasClass('is-active'))
        
        {
            $(this).removeClass('is-active');
            $('.c-row--hide-image-2').removeClass('is-active');
            $(this).html("Show more");
            $('body').removeClass('showImages');
        }
        
    });

    //Force images to load if already hidden
    $('.c-button--show-more-images').on('click', function () {
        setTimeout(function() {
            $('body').trigger('revealOffcanvas');
        }, 100);
    });

    $('.c-button--show-more-images-2').on('click', function () {
        setTimeout(function() {
            $('body').trigger('revealOffcanvas');
        }, 100);
    });

    //Click black background of offcanvas to close navigation
    $('.c-offcanvas').on('click', function (e) {
        if (e.target == this) {
            $('body').removeClass('navigation--is-active');
        }
    });

     /**
     * Add a 'Back' link to sub menus
     */
    $('.sub-menu').each(function() {
        $(this).prepend('<li class="menu__item menu__item--back">Back</li>');
    });

    $('.menu__item--back').on('click', function(e) {
        $(this).closest('.is-active').removeClass('is-active');
        $(this).parents('nav').removeClass('is-active');
        $('.sub-menu > .menu__item').hide();
        $('body').removeClass('sub-nav-is-active');
    });


    /**
     * Register click events on sub navigation toggles
     */

    $('.sub-menu > .menu__item').hide();
    $('.menu__item--has-children > a').on('click', function(e) {

        e.preventDefault();
        $('.c-nav-primary').addClass('is-active');
        $('body').addClass('sub-nav-is-active');

        var parent = $(this).parent(),
            menu = $(this).closest('ul');
        menu.find('.menu__item').removeClass('is-active');

        parent.addClass('is-active');


        $('.sub-menu > .menu__item').show();

        $(".menu__item:not(.is-active) > .sub-menu > .menu__item").hide();
        
    });

    var $videoTime = $('.hero-time').html();

    $('.c-divider').append('<div class="video-bar"></div>');

    $('.video-bar').animate({
        width: "90%"
    }, $videoTime*1000 );

    if($videoTime == 0){
        $('.c-divider').hide();
    };

    $('.c-cta--layout-tile').addClass('none-hovered');

    $('.c-cta--layout-tile').hover(
        function() {
            $( this ).addClass('is-hovered');
            $('.c-cta--layout-tile').removeClass('none-hovered');
        }, function() {
            $( this ).removeClass('is-hovered');
            $('.c-cta--layout-tile').addClass('none-hovered');
        }
    );

})(jQuery);